@import 'src/assets/scss/_variables.scss';

.data-dictionary-textarea {
    textarea {
        resize: none;
        width: 100%;
        border-color: #ccc;
        border-radius: 4px;
        &:focus-visible {
            outline-color: $secondar-color !important;
        }
    }
}

.bg-database{
    background-color: #fff !important;
    padding: 18.5px 16px !important;
}

.tree-view-accordian {
    .attribute-column {
      width: 240px;
      position: fixed;
      top: 165px;
    }
  
    .accordian-sec {
      margin-left: 240px;
    }
  
    .data-list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.146);
  
      .list-item {
        font-size: 14px;
      }
    }
  
    ::placeholder {
      color: #ccc;
      font-weight: 400;
    }
  
    .border-right {
      border-right: 1px solid #cccc;
      height: calc(100vh - 165px);
      overflow: auto;
    }
  
    .accordian-form-space {
      margin-bottom: 20px !important;
    }
  }

