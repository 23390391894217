.fa-circle-stop-tasks{
    font-size: 12px;
}

.logo-container{
    display: flex;
    align-items: center;
    width: 100%;
    
    .logo{
        background-color: #fff;
        text-align: center;
        // width: 2.5rem;
        // min-width: 2.5rem;
        border-radius: 0.313rem;
        color: #0d6efd;
        // padding: 0.313rem;
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;
        border: none;
    }

    .logo-text{
        margin-left: 1rem;
        font-size: 21px;
        font-weight: 700;
        color: #fff;
        top: 0;
        position: relative;
    }
    .btn-close{
        margin-left: 3rem;
        cursor: pointer;
        width:2rem;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        .fa-solid
        {
            color: #fff;
            font-size: 24px;
        }
    }
}