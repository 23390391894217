@import 'src/assets/scss/_variables.scss';

.bg-trillo{
    background-color: #1e88e5;
    filter: brightness(0.6);
}
.authentication-body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/bg-img2.svg) ;
    padding: 30px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   
}
.authentication-cards{
    a{
        text-decoration: none !important;
        color: #1E88E5 !important;
        font-weight: 500;
    }
    .form-check-input[type=checkbox] {
        font-size: 16px !important;
        border-color: #1E88E5 !important;
        border-radius: 2px !important;
        margin-top: 3px !important;
    }
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
}
.logo-text-dark{
    background-image: url('../../assets/images/logo-text-dark.png');
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 140px;
    background-size: contain;
    top: 30px;
    left: 30px;
    position: absolute;
    cursor: pointer;
}

.container {
    max-width: 550px !important;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    ::placeholder {
        font-size: 14px;
    }


    .form {
        height: 100%;
        width: 100%;
        background-color: #fff;
    }

    .img-section {
        border-radius: 10px 0 0 10px;
        background-color: #333;
    }

}

form .form-content .title {
    font-size: 24px;
    font-weight: 500;
    color: #333;
}

form .signup-form .title::before {
    width: 20px;
}

form .form-content .input-boxes {
    margin-top: 30px;
}

form .form-content .input-box {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 10px 0;
    position: relative;

    input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        padding: 0 30px;
        font-size: 17px;
        font-weight: 500;
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
    }

    i {
        position: absolute;
        color: $primary-color;
        font-size: 16px;
    }
}

.form-content .input-box input:focus,
.form-content .input-box input:valid {
    border-color: $primary-color;
}

form .form-content .text {
    font-size: 14px;
    font-weight: 500;
    color: #333;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

}

form .form-content .button {
    color: #fff;
    margin-top: 40px;

    input {
        color: #fff;
        background: $primary-color;
        padding: 0;
        border-radius: 6px;
        transition: all 0.4s ease;

        &:hover {
            background: $secondar-color;
        }
    }
}

form .form-content label {
    color: $secondar-color;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.orgs-list{
    transition: 0.5s ease;
    width: 75%;
}
.orgs-list:hover{
    transform: scale(1.02);
    cursor: pointer;
    background-color: $primary-color;
    color: #fff;
}
.orgs-sec{
    max-height: 300px;
    overflow: auto;
}

.mailbox{
    max-width: 500px !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 10px;
    
}
.input-box {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 8px 0 10px 0;
    position: relative;

    input {
        height: 100%;
        width: 100%;
        outline: none;
        padding: 0 40px;
        font-size: 17px;
        font-weight: 500;
        height: 100%;
        border-color: #8098f97d;
        background: #8098f92e;
    }

    
}