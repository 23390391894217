.setting-tabs-height{
    height: calc(100vh  - 190px);
    overflow: auto;
}
.settings-tabs-public-key{
    height: calc(100vh - 124px);
    overflow: auto;
}
.padding-head-sec{
    padding: 14px 12px;
}
.group-sidebar-header {
    padding: 12px 10px !important;
}
.setting-delete{
    padding: 3px 5px !important;
}