.font-10{ font-size: 10px !important; }
.font-11{ font-size: 11px !important; }
.font-12{ font-size: 12px !important; }
.font-13{ font-size: 13px !important; }
.font-14{ font-size: 14px !important; }
.font-15{ font-size: 15px !important; }
.font-16{ font-size: 16px !important; }
.font-18{ font-size: 18px !important; }
.font-30{ font-size: 30px; }
.font-20{ font-size: 20px; }
.font-10_5{
    font-size: 10.5px !important;
}

.fw-700{ font-weight: 700; }
.font-400{
    font-weight: 400 !important;
}
.font-500{
    font-weight: 500 !important;
}
.font-600{
    font-weight: 600 !important;
}
.font-700{
    font-weight: 700 !important;
}