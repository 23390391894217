.card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-title+.card-subtitle {
    color: #1f88e5 !important;
}

@media(max-width: 992px) {
    .tickets-counter-media{
        margin-bottom: 24px !important;
    }
}