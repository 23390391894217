@import 'src/assets/scss/_variables.scss';


ul.context-menu {
    list-style-type: none;
  }
  
  li.file-checkbox {
    display: inline-block;
  }
  
  input[type="checkbox"][id^="myCheckbox"] {
    display: none;
  }
  
  .custom-checkbox {
    padding: 10px;
    display: block;
    position: relative;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
  }
 
  .custom-checkbox img {
    height: 70px;
    width: 80px;
    object-fit: contain;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
    padding: 10px;
  }

  input.custom-checkbox {
    position: absolute;
    opacity: 0;
    
    &:checked + label svg path {
      stroke-dashoffset: 0;
    }
    
    &:focus + label {
      transform: scale(1.03);
    }
  }
  
  .custom-checkbox + label {
    display: block;
    border: 2px solid #5d5c5c;
    width: 20px; height: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s ease;
    
    &:active {
      transform: scale(1.05);
      border-radius: 30px;
    }
    
    svg {
      pointer-events: none;
      position: relative;
      top: -4px;
      padding: 2px;
      path {			
        fill: none;			
        stroke: #5d5c5c;
        stroke-width: 8px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 100;
        stroke-dashoffset: 101;
        transition: all 350ms cubic-bezier(1,0,.37,.91);
      }
    }
  }

  ul .file-checkbox .form{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
  }
  .grid-view-checkbox{
    display: none;
  }
  .file-checkbox:hover {
  & .grid-view-checkbox{
    display: block;
  }
  & .custom-checkbox{
    background-color: #EDEDED;
  }
}

.search-files input:focus {
  box-shadow: 0 0 0 0;
}

.grid-checkbox-position{
  position: absolute;
  left: 15px;
  z-index: 1;
  top: 16px;
}

.form-group-checkbox , .grid-view-checkbox{

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      background-color: transparent;
      border: 2px solid #333435;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 13px;
    border: solid #333435;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}