@import 'src/assets/scss/_variables.scss';
  
  table thead {
    position: sticky !important;
    top: 0 !important;
    background-color: transparent !important;
    color: #fff !important;   
     z-index: 1 !important;
}
table tr {
  cursor: pointer;
}

.table-whitespace-pre {
  // white-space: pre;
}

.table-whitespace-break {
  white-space: break-spaces;
  line-height: 150%;
  table-layout: fixed;
}

.table-whitespace-break .name-col {
  width: 30%;
  word-wrap: break-word;
}

.table-whitespace-break .value-col {
  width: 70%;
  word-wrap: break-word;
}