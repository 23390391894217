.gap-10{
    gap: 16px;
}

.git-integration-Auth{
    text-decoration: none;
}

.git-custom-scroller{
    height: calc(100vh - 160px);
    overflow-x: hidden;
    overflow-y: scroll;
}
.p-32{
    padding: 16px 16px;
}
.git-left-part{
    width: 240px !important;
    border-right: 1px solid #ccc !important;
    height: calc(100vh - 64px) !important;
    position: fixed;
}

.git-right-part{
    margin-left: 240px !important;
}