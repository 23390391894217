@import 'src/assets/scss/_variables.scss';


.bg-7d2ae8{
    background-color: $primary-color;
}
table th.bg-EDEDED,table td.bg-EDEDED{
    background-color: #EDEDED;
}
table td.bg-Blue{
    background-color: #1F88E6;
}
table td.primary-text{
    color: #1F88E6 !important;
}
.bg-EDEDED{
    background-color: #EDEDED;
}

.item-right-clicked {
    outline: 1px solid #1F88E6;
    background-color: #EDEDED;
}

h1, h2, h3, h4, h5, h6{
    color: #455a64;
}
p , li , dd ,dt{
    color: #67757c;
}

.content ul li a{
    color: #1F88E6;
}
.text-1F88E6{
    color: #1F88E6 !important;
}
.bg-1F88E6{
    background-color: $primary-color;
}
.text-455a64{
    color: rgba(0,0,0,.66);;
}
.selected {
    background-color: #2088e6 !important;
    color: #fff !important;
  }
  .selectedRow {
    background-color: #1E88E5 !important;
    color: #fff !important;
}