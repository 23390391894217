.sys-classes{
    margin-left: 5px;
    cursor: pointer;
  }
  .add-new-database-icon{
    font-size: 24px;
    white-space: nowrap;
  }
  
  .delete-database{
    color: #ef5350 !important;
    font-size: 23px !important;
  }

.list-table {
    border-right: 1px solid rgba(0, 0, 0, 0.146);
    height: calc(100vh - 76px) !important;
    overflow-y: auto !important;
  }

@media (max-width: 991.98px) { 
  .list-table {
    border-right: 1px solid rgba(0, 0, 0, 0.146);
    height: auto !important;
    overflow-y: auto !important;
  }
 }

 .data-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.146);
  }
  .data-list .list-item {
    font-size: 14px;
  }

  .attribute-selected:active {
    background-color: $primary-color !important;
    color: #fff !important;
}

.tabs-section {
    .nav-tabs .nav-link.active {
        border-bottom: 2px solid $primary-color !important;
        color: $primary-color !important;
        border: none;
        background-color: transparent !important;
        font-weight: 500 !important;
        font-size: 12px !important;
    }

    .nav-tabs .nav-link {
        border: none !important;
        color: #41464b !important;
        font-weight: 500 !important;
        font-size: 12px !important;

    }
}

.navs-padding{
    padding: 16.5px 0 0;
  } 