@import 'src/assets/scss/_variables.scss';

.uploadcsv-modal {
  overflow: auto;
  max-height: calc(100vh - 50rem);
}
.users-table {
  max-height: calc(100% - 48px);
  overflow: auto;
  .table> :not(caption)>*>* {
    word-break: break-word !important;
    width: 10rem !important;
  }
}

.users-manager-search .search-icon,
.user-manager-roles .search-icon ,
.groups-search .search-icon {
  position: absolute;
  top: 6px;
  left: 13px;
}

.pl-35 {
  padding-left: 35px !important;
}


.roles-table {
  height: calc(100vh - 295px);
  overflow: auto;

  th:hover .sorting-icon {
    visibility: visible;
  }

}

.sorting-icon {
  visibility: hidden;
  transition: 0.3s;
  color: #f3f3f3;

  & hover {
    .sorting-icon {
      transform: scale(2.1);
      color: #fff;
    }
  }
}

.page-titles {
  background: #ffffff;
  padding: 15px;
  box-shadow: 1px 0 5px rgb(0 0 0 / 10%);
}
.search-section input {
  padding-right: 40px !important;
}
.groups-table {
  height: calc(100vh - 225px);
  overflow: auto;
}


.logs-table {
  height: calc(100vh - 215px);
  .table-loader {
    position: relative;

    .logs-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      color: #1E88E5 !important;
    }
  }
}
.logs-right-section {
  
  .log-details-heading {
    padding: 8px;
    background-color: #f8f8f8;
  }

  pre {
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 6%) !important;
    background: white;
    padding: 10px;
    border-radius: 6px;
    max-height: 240px;
    overflow: auto;
  }
}
.DataExtraction-right-section{
  .log-details-heading {
    padding: 8px;
    background-color: #f8f8f8;
  }

  pre {
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 6%) !important;
    background: white;
    padding: 10px;
    border-radius: 6px;
    max-height: 308px;
    overflow: auto;
  }
}