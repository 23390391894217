.form-group-checkbox , .grid-view-checkbox{
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label {
      position: relative;
      cursor: pointer;
  
      &:before {
        content: "";
        background-color: transparent;
        border: 2px solid #333435;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px !important;
      }
    }
    input:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 6px;
      height: 13px;
      border: solid #333435;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .functions-attribute-tbl{
    height: calc(100vh - 175px) !important;
    overflow: auto !important;
}
.function-navs-padding{
  padding: 16px 0 0;
} 