.alert-position {
  position: fixed;
  top: 46px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999 !important;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.custom-alert {
  padding: 4px;
  border-radius: 4px;
}

.success-alert {
  background-color: #d4edda;
  color: #155724;
  border-left: 4px solid #00a701;
}

.danger-alert {
  border-left: 5px solid #920303;

  background-color: #d74646;
  color: #fff;
}

/* check circle */
.check-circle {
  background-image: url("../../assets/images/icons/check-circle.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

/* error icon */
.error-icon {
  background-image: url("../../assets/images/icons/error-icon.jpeg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: cover;
}
